:root {
  --palette-jade: #02b875;
  --palette-celery: #a0d76a;
  --palette-candlelight: #ffd012;
  --palette-sunrise: #ffad1e;
  --palette-scotchMist: #fffade;
  --palette-pearlLusta: #fffdf6;
  --palette-dodger: #448ee1;
  --palette-amethyst: #af64ce;
  --palette-permission: #e43538;
  --palette-ferrari: #c82124;
  --palette-mintCream: #f6feff;
  --palette-athens: #f9f9f9;
  --palette-smoke: #f3f5f7;
  --palette-porcelain: #ebeef0;
  --palette-zircon: #dadfe3;
  --palette-chateau: #acb6be;
  --palette-raven: #6f7c87;
  --palette-elephant: #183247;
  --palette-rhino: #284255;
  --palette-white: #ffffff;
  --palette-jungle: #00a886;
  --palette-azure: #2c5cc5;
  --palette-skylight: #e5f2fd;
  --palette-clara: #283da5;
  --palette-casablanca: #e86f25;
  --palette-separator: #e5e5e5;
  --palette-crescent: #fafafa;
  --palette-peachGreen: #f8fdff;
  --palette-coralBlack: #e0e0e0;
  --palette-darkCream: rgba(188, 203, 221, 0.54);
  --palette-litchi: #e0f4f0;
  --palette-litchib: #b4e4da;
  --palette-mangoYellow: #fffae6;
  --palette-transparent: rgba(255, 255, 255, 0);
  --palette-translucentBlue: #2e6ed8;
  --palette-oceanBlue: #2b5bc4;
  --palette-lightBlue: #5b6ab9;
  --palette-knighthood: #f7f8fa;
  --palette-blackKnight: #13344e;
  --palette-orangeBlush: #ffd6a3;
  --palette-orangeIQ: #cb6040;
  --palette-minuteGrey: #9a9a9a;
  --palette-oceanDarkBlue: #c3c7d6;
  --palette-minuteGreyDark: #333333;
  --palette-orangePeach: #c4602b;
  --palette-orangePeachBg: #fffced;
  --palette-orangeSummary: #e64e21;
  --palette-dragonGold: #c3844f;
  --palette-dragonRed: #c74040;
  --palette-blueBoxShadow: rgba(64, 58, 143, 0.13);
  --palette-creamBlueOutline: rgba(0, 103, 244, 0.247);
  --palette-creamBlue: #8ea1b9;
  --palette-blackSeparator: #707070;
  --palette-passion: #d72d30;
  --palette-ghostBlack: #aab6c9;
  --palette-pulpyOrange: #ff9300;
  --palette-peach: #ffd0d6;
  --palette-babylon: #f89fa1;
  --palette-coral: #f2797b;
  --palette-darkBlackBoxShadow: #00000029;
  --palette-e-50: #dff0ff;
  --palette-e-100: #bedbf5;
  --palette-e-200: #a2c0de;
  --palette-e-300: #83a6c8;
  --palette-e-400: #6b92b6;
  --palette-e-500: #527fa5;
  --palette-e-600: #447093;
  --palette-e-700: #345c7c;
  --palette-e-800: #264966;
  --palette-e-900: #12344d;
  --palette-s-5: #fcfdfd;
  --palette-s-10: #f7f9fa;
  --palette-s-25: #f5f7f9;
  --palette-s-50: #ebeff3;
  --palette-s-65: #e7ebef;
  --palette-s-75: #e6e8eb;
  --palette-s-85: #d0d7df;
  --palette-s-100: #cfd7df;
  --palette-s-125: #ced7de;
  --palette-s-200: #b1bdc8;
  --palette-s-300: #92a2b1;
  --palette-s-400: #7b8e9f;
  --palette-s-500: #647a8e;
  --palette-s-600: #576c7d;
  --palette-s-700: #475867;
  --palette-s-750: #3f3f3f;
  --palette-s-800: #384551;
  --palette-s-900: #27313a;
  --palette-c-50: #fef1e1;
  --palette-c-100: #fddbb5;
  --palette-c-200: #fac386;
  --palette-c-300: #f8ab59;
  --palette-c-400: #f69a3c;
  --palette-c-500: #f48928;
  --palette-c-600: #ef7e27;
  --palette-c-700: #e86f25;
  --palette-c-800: #e16023;
  --palette-c-900: #d7461f;
  --palette-j-50: #e0f5f1;
  --palette-j-100: #b4e5da;
  --palette-j-200: #82d5c2;
  --palette-j-300: #4dc4aa;
  --palette-j-400: #1cb697;
  --palette-j-500: #00a886;
  --palette-j-600: #009a79;
  --palette-j-700: #008969;
  --palette-j-800: #00795b;
  --palette-j-900: #005c3f;
  --palette-p-50: #ffecf0;
  --palette-p-100: #ffd0d6;
  --palette-p-200: #f89fa1;
  --palette-p-300: #f2797b;
  --palette-p-400: #ff5959;
  --palette-p-500: #ff473e;
  --palette-p-600: #f73f3e;
  --palette-p-700: #e43538;
  --palette-p-800: #d72d30;
  --palette-p-900: #c82124;
  --palette-a-50: #e5f2fd;
  --palette-a-100: #bbdcfe;
  --palette-a-200: #90c6fe;
  --palette-a-300: #64b0fc;
  --palette-a-400: #459ffc;
  --palette-a-500: #2e8efa;
  --palette-a-600: #2f80eb;
  --palette-a-700: #2e6ed8;
  --palette-a-800: #2c5cc5;
  --palette-a-900: #283da5;
  --palette-black: #000000;

  --colors-border-secondary: var(--palette-s-50);
  --colors-text-primary: var(--palette-e-900);
  --colors-background-secondary: var(--palette-s-10);
  --colors-border-hover: var(--palette-s-700);
  --colors-link: var(--palette-a-800);
  --colors-primary: var(--palette-a-800);
}

* {
  font-family: -apple-system, '.SFNSText-Regular', 'San Francisco', BlinkMacSystemFont, 'Segoe UI',
    Roboto, Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Lucida Grande',
    Arial, sans-serif;
}

* {
  margin: 0px;
  box-sizing: border-box;
}

a,
a:hover {
  text-decoration: none;
}
